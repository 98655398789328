.App {
  text-align: center;
  min-height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.runLobbyTable{
  width: 60%;
  margin-left: 20%;
  margin-top: 5%;
}

.runLobbyTable tr,td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.runReadyTable{
  width: 40%;
  margin-left: 30%;
  margin-top: 5%;
}

.runReadyTable tr,td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.runActiveTable{
  width: 40%;
  margin-left: 30%;
  margin-top: 5%;
}

.runActiveTable tr,td{
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.aboutGithubLinks a{
  text-decoration: none;
}

.aboutGithubLinks li{
  list-style-type: none;
}

.pictureOfLadyRunning{
  margin-top:1%;
}

.aboutHR{
  width: 5%;
}

.wholePageImage{
    position: fixed; 
    top: -50%; 
    left: -50%; 
    width: 200%; 
    height: 200%;
}

.wholePageImage img{
    position: absolute; 
    top: 0; 
    left: 0; 
    right: 0; 
    bottom: 0; 
    margin: auto; 
    min-width: 50%;
    min-height: 50%;
}

.welcomeText{
  color: white;
  position: absolute;
  
  left: 45%;
  top: 15%;
}

.joinButtonHomePage{
  position: fixed;
  top: 140px;
  left: 1%;
}

.grayBackground{
  background-color: #D3D3D3;
}

.runActiveTables{
  margin: 0;
  padding-left: 40%;
  padding-top: 50px;
}

.stopRun{
  position: absolute;
  top: -20px;
  left: -270px;
}
.centerDiv{
  margin: 0;
}

.runnerLogoWelcome{
  float: left;
  margin-left: 14%;
  margin-top: -5%;
}

.lineGraphDashboard{
  margin-left: 52%;
  margin-top: 10%;
}

.dashboardTable{
  float: right;
  margin-right: 30%;
  margin-top: 0%;
  margin-bottom: 4%;
}

.footerLinks{
  list-style-type: none;
  display: flex;
  color: #115293;
  
}

.footerLinks a{
  text-decoration: none;
  margin-left: 1%;
}

.footerLinks li{
  margin-left: 7%;
  margin-top: 4%;
}

.footerStyle{
  display: flex;
  position: fixed;
  bottom: 0;
  height: 60px;
  width: 100%;
  color: #115293;
  padding-left: 5%;
  background-color: lightgrey;
}

